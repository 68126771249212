<template>
  <y_layout>
    <el-alert title="请完整填写商家信息，并添加核销员、店铺、卡券等信息，如缺少信息可能会造成活动无法正常进行！" type="warning"></el-alert>
    <YTabs v-model="active_tab" style="margin-top: 1rem">
      <YTabPane label="基础信息" name="basic">
        <el-card shadow="hover">
          <el-form ref="form" :model="form" label-width="150px" style="padding: 2rem;padding-right: 6rem;width: 50rem">
            <el-form-item label="商家名称">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="区域范围">
              <yChooseArea :val="form" @change="areaChange"></yChooseArea>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="form.link_phone"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="form.link_name"></el-input>
            </el-form-item>
            <el-form-item label="商家logo">
              <yUploadImg v-model="form.logo"></yUploadImg>
            </el-form-item>
            <el-form-item label="商家主题色">
              <y_color_choose v-model="form.theme_bg_color"></y_color_choose>
              <span class="y-desc">卡券列表中调用</span>
            </el-form-item>
            <el-form-item label="商家微信二维码">
              <yUploadImg v-model="form.wx_qrcode"></yUploadImg>
            </el-form-item>
            <el-form-item label="客服链接">
              <el-input v-model="form.waiter_link"></el-input>
            </el-form-item>
            <el-form-item label="所属行业">
              <yChooseIndustry v-model="form.industry_id"></yChooseIndustry>
            </el-form-item>
            <el-form-item label="管理员">
              <y_choose_member v-model="form.manager_uid"></y_choose_member>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </YTabPane>
      <YTabPane label="主页展示信息" name="info">
        <el-card shadow="hover">
          <el-form ref="form2" :model="form" label-width="150px" style="padding: 2rem;padding-right: 6rem;width: 50rem">
            <el-form-item label="主页视频">
              <y_upload_video v-model="form.video"></y_upload_video>
            </el-form-item>
            <el-form-item label="富文本介绍">
              <y_editor v-model="form.desc_rich_text"></y_editor>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </YTabPane>
      <YTabPane card label="轮播图" name="banner">
        <el-table :data="img_list">
          <el-table-column label="轮播图图片：建议尺寸700*300">
            <template #default="s">
              <el-image :src="s.row.file | tomedia" style="height: 3rem;width: 3rem"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #header>
              <el-button @click="uploadNewImgListItem" type="primary" size="mini">添加图片</el-button>
              <y_upload_img_choose ref="uploadImgList"></y_upload_img_choose>
            </template>
            <template #default="s">
              <el-button @click="delImgList(s.$index)" type="danger" size="mini">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </YTabPane>
    </YTabs>
<!--    <el-tabs style="margin-top: 1rem" v-loading="loading">-->
<!--      <el-tab-pane label="基础信息">-->
<!--        <el-card shadow="hover">-->
<!--          <el-form ref="form" :model="form" label-width="150px" style="padding: 2rem;padding-right: 6rem;width: 50rem">-->
<!--            <el-form-item label="商家名称">-->
<!--              <el-input v-model="form.title"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="区域范围">-->
<!--              <yChooseArea :val="form" @change="areaChange"></yChooseArea>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="联系电话">-->
<!--              <el-input v-model="form.link_phone"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="联系人">-->
<!--              <el-input v-model="form.link_name"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="商家logo">-->
<!--              <yUploadImg v-model="form.logo"></yUploadImg>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="商家主题色">-->
<!--              <y_color_choose v-model="form.theme_bg_color"></y_color_choose>-->
<!--              <span class="y-desc">卡券列表中调用</span>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="商家微信二维码">-->
<!--              <yUploadImg v-model="form.wx_qrcode"></yUploadImg>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="客服链接">-->
<!--              <el-input v-model="form.waiter_link"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="所属行业">-->
<!--              <yChooseIndustry v-model="form.industry_id"></yChooseIndustry>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="管理员">-->
<!--              <y_choose_member v-model="form.manager_uid"></y_choose_member>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button type="primary" @click="onSubmit">保存</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </el-card>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="主页展示信息">-->
<!--        <el-card shadow="hover">-->
<!--          <el-form ref="form2" :model="form" label-width="150px" style="padding: 2rem;padding-right: 6rem;width: 50rem">-->
<!--            <el-form-item label="主页视频">-->
<!--              <y_upload_video v-model="form.video"></y_upload_video>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="富文本介绍">-->
<!--              <y_editor v-model="form.desc_rich_text"></y_editor>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button type="primary" @click="onSubmit">保存</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </el-card>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="轮播图">-->
<!--        <el-table :data="img_list">-->
<!--          <el-table-column label="轮播图图片：建议尺寸700*300">-->
<!--            <template #default="s">-->
<!--              <el-image :src="s.row.file | tomedia" style="height: 3rem;width: 3rem"></el-image>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="操作">-->
<!--            <template #header>-->
<!--              <el-button @click="uploadNewImgListItem" type="primary" size="mini">添加图片</el-button>-->
<!--              <y_upload_img_choose ref="uploadImgList"></y_upload_img_choose>-->
<!--            </template>-->
<!--            <template #default="s">-->
<!--              <el-button @click="delImgList(s.$index)" type="danger" size="mini">移除</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->

  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import yUploadImg from "@/components/y_upload_img";
import y_choose_member from "@/components/y_choose_member";
import yChooseIndustry from "@/components/y_choose_industry"
import yChooseArea from "@/components/y_choose_area";
import y_upload_video from "@/components/y_upload_video";
import y_editor from "@/components/editor/y_editor";
import y_upload_img_choose from "@/components/y_upload_img_choose";
import y_color_choose from "@/components/y_color_choose";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "edit",
  components:{
    YTabPane,
    YTabs,
    y_upload_img_choose,
    y_layout,
    yUploadImg,
    yChooseIndustry,
    yChooseArea,
    y_choose_member,
    y_upload_video,
    y_editor,
    y_color_choose,
  },
  data(){
    return{
      form:{
        title:"",
        logo:"",
        link_phone:"",
        link_name:"",
        wx_qrcode:"",
        waiter_link:"",
        theme_bg_color:"",
        industry_id:0,
        province_code:"",
        province_title:"",
        city_code:"",
        city_title:"",
        county_code:"",
        county_title:"",
        town_code:"",
        town_title:"",
        manager_uid:0,
        desc_rich_text:"",
        video:"",
        img_list:"",
      },
      img_list:[],
      edit_id:0,
      loading:false,
      active_tab:"basic",
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.loadEdit();
    }
  },
  methods:{
    uploadNewImgListItem(){
      this.$refs.uploadImgList.showUpload(e=>{
        this.img_list.push({file:e});
        this.onSubmit(false);
      })
    },
    delImgList(key){
      this.img_list.splice(key,1);
      this.onSubmit(false);
    },
    loadEdit(){
      this.loading = true;
      this.$api.merchant.one({id:this.edit_id}).then(res=>{
        this.form = res;
        if(res.img_list !== ""){
          this.img_list = JSON.parse(res.img_list);
        }
        this.loading = false;
      })
    },
    areaChange(e){
      this.form.province_code = e.province_code;
      this.form.province_title = e.province_title;
      this.form.city_code = e.city_code;
      this.form.city_title = e.city_title;
      this.form.county_code = e.county_code;
      this.form.county_title = e.county_title;
      this.form.town_code = e.town_code;
      this.form.town_title = e.town_title;
    },
    onSubmit(back=true){
      this.form.manager_uid = parseInt(this.form.manager_uid);
      if (this.img_list.length > 0){
        this.form.img_list = JSON.stringify(this.img_list);
      }
      if(!this.form.id){
        this.$api.merchant.add(this.form).then(()=>{
          this.$message.success("操作成功");
          if(back){
            this.$router.push({
              name:"merchant",
              params:this.$route.params,
            })
          }
        })
        return;
      }
      this.$api.merchant.update(this.form).then(()=>{
        this.$message.success("操作成功");
        if(back){
          this.$router.push({
            name:"merchant",
            params:this.$route.params,
          })
        }
      })
    }
  }
}
</script>
